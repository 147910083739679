<template>
  <div class="hot-label">
    <div class="title">
      <h1>热门技术点</h1>
      <a :href="'/classify' + addHrefSearch()">More ></a>
    </div>
    <div class="label-container">
      <ul>
        <li
          :style="{
            borderColor: colorArr[index % 5] ? colorArr[index % 5] : '#FF842B',
            color: colorArr[index % 5] ? colorArr[index % 5] : '#FF842B',
          }"
          @click="navToVideoList(label.id)"
          v-for="(label, index) in hotLabelList"
        >
          <span v-html="label.lableName"></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getHotLabels } from "network/common";

export default {
  name: "HotLabels",
  data() {
    return {
      hotLabelList: [],
      colorArr: ["#028ed7", "#00c28b", "#ff532c", "#8932da", "#0055d0"],
    };
  },
  methods: {
    navToVideoList(id) {
      sessionStorage.setItem("labelId", id);
      this.$router.push({
        path: "/classify",
        // query: { labelId: id, ...this.$route.query },
        query: this.$route.query,
      });
    },
    async getHotLabels() {
      const res = await getHotLabels();
      this.hotLabelList = res.data;
    },
  },
  created() {
    this.getHotLabels();
  },
};
</script>

<style scoped lang='less'>
.hot-lable {
  width: 343px;
  box-sizing: border-box;
  padding: 21px 20px 97px 20px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 19px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cccccc;
    position: relative;
    padding-bottom: 12px;
    h1 {
      font-size: 16px;
    }
    a {
      color: #018dd6;
      font-size: 14px;
      margin-top: 2px;
    }
    &::before {
      content: "";
      border-style: solid;
      border-width: 0 7px 7px;
      border-color: transparent transparent #018dd6;
      position: absolute;
      top: 26px;
      left: 34px;
    }
    &::after {
      content: "";
      height: 2px;
      width: 78px;
      display: inline-block;
      position: absolute;
      background: #018dd6;
      top: 32px;
      left: 0px;
    }
  }
  .lable-container {
    min-height: 266px;
    ul {
      display: flex;
      //justify-content: space-around;
      // margin-top: 28px;
      flex-wrap: wrap;
      li {
        padding: 2px 12px;
        list-style: none;
        margin-top: 15px;
        // height: 40px;
        color: #ff842b;
        border: 1px solid #ff842b;
        border-radius: 13px;
        cursor: pointer;
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }
}
</style>